#section-1 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-content: center;
    justify-content: center;

    background-image: url('../images/background.svg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;

    h1 {
        margin: 0;
    }

    .down-chevron {
        position: absolute;
        width: 3vw;
        min-width: 32px;
        bottom: 5vh;
        margin: 0 auto;

        svg {
            width: 100%;
            height: auto;
        }
    }
    
    .tape {
        height: 40vh;
        width: auto;
        position: absolute;
        right: -7%;
        top: -9%;
        z-index: 10;
    }

    .main-logo {
        width: 65vw;
        height: auto;
        scale: 0.1;
        opacity: 0;
    }

    .builder {
        width: auto;
        height: 75vh;
        position: absolute;
        bottom: -100%;
    }

    .speech-bubble {
        background-image: url('../images/speech-bubble.svg');
        background-size: contain;
        background-position: center;      
        background-repeat: no-repeat;  
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 60%;
        top: 10%;
        width: 500px;
        height: 350px;
        scale: 0.1;
        opacity: 0;        

        p {
            width: 75%;
            text-transform: uppercase;
            font-size: 2em;
            line-height: 1.1em;
            text-align: center;    
            color: #ee5023;      
        }
    }

    canvas {
        position: fixed;
        width: 177vh;
        height: 100vh;
        opacity: 0;
    }    

}

@media screen and (max-width: 76em) {
    #section-1 {

        .speech-bubble {
            left: initial;
            right: 5%;
            top: 5%;
            width: 40vw;
            height: 28vw;    
    
            p {
                font-size: 2.8vw;
            }
        }

    }
}


@media screen and (max-width: 48em) {

    #section-1 {

        .tape {
            height: 30vh;
        }

        .main-logo {
            width: 80%;
        }

        canvas {
            width: 177vh;
        }
    }
}

@media screen and (max-width: 28em) {
    #section-1 {

        .speech-bubble {
            left: initial;
            right: 5%;
            top: 5%;
            width: 80vw;
            height: 56vw;    
    
            p {
                font-size: 5vw;
            }
        }

    }
}