#section-4 {
    height: auto;
    width: 100vw;   
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    overflow: hidden;  
    position: relative;
    background-image: url('../images/bling-2.svg');
    background-size: contain;
    background-position: top;

    .gallery-container {
        width: 80%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-self: center;

        img {
            width: 65%;
            height: auto;
            margin: 0 2.5%;            
            mask-image: url('../images/star.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center center;
            transform: rotate(10deg);
            margin-bottom: -8%;

            &:nth-child(even) {
                margin-left: auto;     
                transform: rotate(-10deg);
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
            }
      
        }
    }

}

@media screen and (max-width: 79em) {

}

@media screen and (max-width: 48em) {

    #section-4 {

        .gallery-container {
            width: 100%;

            img {
                width: 85%;
                margin-bottom: 0%;
                margin-left: -5%;

                &:nth-child(even) {                    
                    margin-right: -5%;     
                    transform: rotate(-10deg);
                }
    
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
          
            }
        }        
    }
    
}

@media screen and (max-width: 28em) {

}