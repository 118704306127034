* {
    cursor: url('../images/spoon.png') 23 114, auto;   
}

body {
    font-family: 'Espiritu', sans-serif;    
    background-color: #ee5023;
    color: #f7ed55;
    margin: 0; 
}

.terms {
    padding: 8vw;    
}

main {

    .bolt {
        width: 3vw;
        height: auto;
        position: fixed;

        &.animate {
            animation: spin 1s infinite;            
            animation-timing-function: linear;            
        }
    }    

    .bolt-1 {
        top: 2%;
        left: 2%;
    }

    .bolt-2 {
        top: 2%;
        right: 2%;
    }
    
    .bolt-3 {
        bottom: 2%;
        left: 2%;
    }
    
    .bolt-4 {
        bottom: 2%;
        right: 2%;
    }    
}

@keyframes spin {
    0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
}

section {
    width: 100vw;
    height: 100vh;
}

.loaderContainer {
    background-color: #7000F7;
    height: 100%;
    width: 100%;

    position: absolute;
    top: -100%;
    left: 0;
    z-index: 100;

    display: flex;
    justify-content: center;

    transition-property: top;
    transition-duration: 1500ms;

    .loader {
        display: flex;
        align-self: center;
        width: 30%;
        height: auto;
    }

}

.showLoading {
    top: 0;
}

@media screen and (max-width: 48em) {
    main {

        .bolt {
            width: 30px;
        }   
        
    }
    
}