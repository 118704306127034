#section-3 {
    height: auto;
    width: 100vw;   
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    overflow: hidden;  
    position: relative;
    background-image: url('../images/bling-2.svg');
    background-size: contain;
    background-position: top;
    // background-image: url('../images/background.svg');
    // background-size: cover;
    // background-position: center;

    .bling {
        position: absolute;
        top: 10%;
        left: 15%;
        height: 80%;
        width: auto;
    }

    .product-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100vw;        

        #canvas-container {
            width: 30vw;                     
            height: 100vh;
            background-image: url('../images/star.svg');
            background-size: contain;
            background-position: center;        
            background-repeat: no-repeat;
        }    

        .product-detail-container {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;        
            flex-wrap: wrap;
            width: 50vw;            

            .title {
                font-size: 2.5em;
                text-transform: uppercase;
                margin-bottom: 0.5em;
            }

            .detail {
                font-size: 2.2em;
                line-height: 1em;
                text-transform: uppercase;  
                margin: 0;
                
                &:before {
                    content:url('../images/bolt.svg');
                    width: 1em;
                    display: inline-block;
                    margin-right: 0.5vw;
                    transform: translateY(5%);
                }
            }

            .soldout-container {

                display: flex;

                .soldout-warning {
                    height: 5em;
                    margin-right: 1em;
                    width: auto;
                    align-self: center;
                }

                .mail-button {
                    color: #FFFFFF;
                    background-color:#ee5023;
                    border: 2px solid #000;
                    transition-duration: 500ms;
                    border-radius: 15px;
                    padding: 0.6em 1em;   
                    padding-top: 0.8em;         
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 1.3em;
                    margin: 2em 0;
                    cursor: pointer;
                    font-family: 'Espiritu', sans-serif; 

                    &:hover {
                        color: #000;
                        border: 2px solid #FFF;
                    }
                }
    
            }



        }

    }
    .essentials-container {
        display: flex;
        justify-content: center;
        width: 100vw;
        
        position: relative;

        .details-container {
            width: 45vw;
            position: relative;
            top: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .essentials-logo {
                width: 100%;
                height: auto;
            }

            a {
                width: 15%;

                .download-button {
                    width: 100%;
                    height: auto;
                }                
            }

            p {
                width: 80%;
                font-size: 2.5vw;                
                text-transform: uppercase;
                text-align: center;
            }
        }

        .helmet {
            width: 40vw;
            height: auto;
            position: absolute;
            top: 20%;
            left: -5%;
        }

        .level {
            width: 45vw;
            height: auto;
            position: absolute;
            bottom: 5%;
            left: 15%;
        }        

        .hammer {
            width: 18vw;
            height: auto;
            position: absolute;
            top: 12%;
            right: 10%;
        }

        .card {
            width: 21vw;
            height: auto;
            position: absolute;
            bottom: 10%;
            right: 10%;            
        }
    }

    .customer-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 100%;        
        opacity: 0;    
        
        p {
            width: 70%;
            font-size: 2.5vw;                
            text-transform: uppercase;
            text-align: center;
        }    
        
        .customer-container {
            display: flex;
            justify-content: center;
            flex-direction: row;              
            width: 80vw;
            height: 65vh;
            gap: 10%;

            a {
                width: 25%;
                display: flex;

                &:first-child {
                    transform: rotate(5deg);
                }
    
                &:last-child {
                    transform: rotate(-5deg);
                }

                .customer-video {
                    background-color: #f7ed55;
                    border: 2px solid #000;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    padding: 7px;
                    position: relative;
                    box-sizing: border-box;

                    .customer {
                        height: auto;
                        width: 100%;
                    }

                    .play-button {
                        width: 25%;
                        align-self: center;
                        position: absolute;
                        left: 37%;
                    }
                }
            }
        }
    }

    .tape-2 {
        height: 40vh;
        width: auto;
        position: absolute;
        left: -15%;
        bottom: -5%;
    }
}

.email-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);   
    z-index: 100;
    width: 40%;
    background-color: #f7ed55;
    border: 1px solid #FFF;
    color: #ee5023;

    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    img {
        width: 25%;
        height: auto;
        margin-top: 20%;
    }

    p {
        font-size: 2.2em;
        line-height: 1.1em;
        text-transform: uppercase;
        width: 80%;
        text-align: center;
    }

    input {
        background-color: #FFF;
        font-size: 1.5em;
        padding: 1em;
        padding-top: 1.2em;
        width: 60%;
        color: #000;
        text-transform: uppercase;
        font-family: 'Espiritu', sans-serif; 
        border: 1px solid #000; 
        margin-bottom: 2em;
        line-height: 1em;
    }

    button {
        background-color: #ee5023;
        font-size: 2em;
        padding: 1em 2em;
        padding-top: 1.1em;
        color: #FFF;
        text-transform: uppercase;      
        border: 1px solid #FFF;  
        margin-bottom: 10vh;
        cursor: pointer;
        font-family: 'Espiritu', sans-serif; 
        
        &:hover {
            color: #ee5023;
            background-color: #FFF;
            border: 1px solid #ee5023; 
        }

        &:disabled {
            opacity: 0.3;
            cursor: default;

            &:hover {
                color: #FFF;
                background-color: #ee5023;
                border: 1px solid #FFF; 
            }            
        }
    }
}


@media screen and (max-width: 79em) {

    #section-3 {
    
        .product-container {        
            flex-direction: column;
            align-items: center;

            #canvas-container {
                height: 100vw;
                width: 100vw;                     
                background-size: 70% 70%;
                background-position: center;
            }

            .product-detail-container {
                height: 50vh;
                width: 100vw;
                transform: translateY(-30%);

                .title {
                    font-size: 2.2em;
                }
        
                .detail {
                    font-size: 1.9em;
                }
        
            }

        }

        .tape-2 {
            left: -60%;
        }
    }
}

@media screen and (max-width: 74em) {

    .email-modal {
        width: 60%;
    }

}

@media screen and (max-width: 48em) {

    .email-modal {
        width: 80%;

        p {
            font-size: 1.6em;
            width: 80%;
        }
    
        input {
            font-size: 1em;
            width: 80%;
        }
    
        button {
            font-size: 1.3em;       
        }
    }
    
}

@media screen and (max-width: 28em) {

    #section-3 {
        background-repeat: no-repeat;
        
        .product-container {        

            #canvas-container {
                height: 150vw;
                width: 100vw;                     
                background-size: 90% 90%;
                background-position: center;
            }

            .product-detail-container {
                flex-wrap: nowrap;

                .title {
                    font-size: 1.7em;
                    padding-left: 1em;
                }
        
                .detail {
                    font-size: 1.4em;
                    padding-left: 1em;
                }
        
                .soldout-container {

                    display: flex;
                    flex-direction: column;
    
                    .soldout-warning {
                        height: 5em;
                        margin-top: 1em;
                    }
    
                    .mail-button {
                        border-radius: 15px;
                        padding: 1em 0.5em;  
                        padding-top: 1.2em;
                        width: 80%;
                        align-self: center;          
                        font-size: 1em;
                        margin: 2em 0;
    
                    }
        
                }                  
            }          
        }
    }

}