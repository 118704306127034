footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap:3em;
    margin-bottom: 5vh;
    
    a {
        text-decoration: none;        
        color: #f7ed55;
        font-size: 1.5em;
        cursor: pointer;

        &:hover {
            color: #FFF;                        
        }
    }
}
