#section-2 {
    height: auto;
    width: 100vw;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;  

    background-image: url('../images/bling.svg');
    background-size: contain;
    background-position: top;
    //background-repeat: no-repeat;

    .bling {
        position: absolute;
        top: 10%;
        left: 15%;
        height: 80%;
        width: auto;
    }

    .text-container {
        width: 90vw;
        align-self: center;
        margin-top: 8em;
        flex-direction: column;
        display: flex;
        justify-content: center;        
        align-items: center;

        .text-1, .text-2 {
            text-transform: uppercase;
            width: 80%;
            font-size: 3em;
            text-align: center;
            margin: 0.5em 0;
        }
    }

    .food-container {
        width: 100%; 
        flex-direction: row;
        display: flex;    
        justify-content: center;        
        
        .food-item {
            display: flex;
            flex-direction: column;
            
            p {
                text-transform: uppercase;
                width: 100%;
                text-align: center;
                font-size: 1.8vw;
            }

            img {
                width: 100%;
                max-width: 199px;
                height: auto;
                align-self: center;
                display: flex;

                &:hover {
                    animation: wiggle 0.25s infinite;
                    animation-direction: alternate;
                    animation-timing-function: ease-in-out;
                }
            }
        }
    }    

    .splat-container {

        width: 100vw;
        height: auto;
        max-width: 1470px;
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2%;

        .splat-text-container {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                height: auto;
                width: 100%;
            }
        }

        .splat-1 {
            transform: translateY(-10%);
        }

        .splat-2 {
           transform: translateY(10%);
        }        
                
    }
     
}

@media screen and (max-width: 48em) {

    #section-2 {
        flex-direction: column;
        height: initial;
        align-items: center;
        

        .text-container {
            width: 90vw;

            .text-1, .text-2 {
                text-transform: uppercase;
                font-size: 23px;
                text-align: center;
                margin: 0.5em 0;
            }
        }        

        .food-container {
            flex-wrap: wrap;
            
            .food-item {
                p {
                    font-size: 19px;
                }
            }
        }    
         
        .splat-container {
            flex-direction: column;
            height: auto;
            align-items: center;
            padding-bottom: 1px;

            .splat-text-container {
                width: 50%;
            }

            .splat-1 {
                transform: translateY(0);
            }
    
            .splat-2 {
                transform: translateY(0);
            } 
        }

    }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   100% { transform: rotate(5deg); }
}